import isEqual from 'lodash/isEqual'
import { defineStore, storeToRefs } from 'pinia'
import { filter } from '@prismicio/client'
import throttle from 'lodash/throttle'

import { prismicIDPattern, slugPattern } from '~/utils/pattern'
import { usePrismicClient } from '~/composables/usePrismicClient'
import type { CategoryType, EnergyRateType, GenericRateType, InsuranceRateType, TelcoRateType } from '@/types'
import { ALL, Category } from '@/types'
import { usePriceEstimator } from '~/composables/usePriceEstimator'
import { useCategoryStore } from './useCategoryStore'
import { filteredEnergyRateList, parseEnergyRate, parseEnergyRateList } from './utils/energy'
import { filteredInsuranceRateList, parseInsuranceRate, parseInsuranceRateList } from './utils/insurance'
import { filteredTelcoRateList, parseTelcoRate, parseTelcoRateList } from './utils/telco'

export const useRateStore = defineStore('rates', () => {
  const { category } = storeToRefs(useCategoryStore())
  const {
    activationCost,
    clientType,
    estimatedConsumeInKwh,
    estimatedConsumeInCubicMeters,
    consumeInCubicMeters,
    consumeInKwh,
    consumeKnown,
    modem,
    priceVariant,
    rateVariant,
    energySubCategory,
    insuranceSubCategory,
    supplier,
    telcoTechnology,
    voice,
  } = storeToRefs(useFilterStore())
  const { getBrandByName } = useBrandStore()
  const { isEnergy, isTelco, isInsurance } = storeToRefs(useCategoryStore())
  const { client } = usePrismicClient()
  const { rateWithPriceInfo } = usePriceEstimator()
  const { setLoading } = useUIStore()
  const route = useRoute()

  const rateList = ref<(GenericRateType)[]>([])

  // Used to show the lead dialog with a selected rate as prop
  const selectedRate = ref<GenericRateType>()

  const energyRateList = computed<EnergyRateType[]>(() =>
    rateList.value.filter((data): data is EnergyRateType => true)
  )

  const telcoRateList = computed<TelcoRateType[]>(() =>
    rateList.value.filter((data): data is TelcoRateType => true)
  )

  const insuranceRateList = computed<InsuranceRateType[]>(() =>
    rateList.value.filter((data): data is InsuranceRateType => true)
  )

  const filteredRateList = computed<(GenericRateType)[]>(() => {
    if (category.value === undefined) {
      return []
    }

    const supplierId = getBrandByName(supplier.value)?.id || ALL

    if (isEnergy.value) {
      return filteredEnergyRateList(
        energyRateList.value,
        {
          clientType: clientType.value,
          priceVariant: priceVariant.value,
          rateVariant: rateVariant.value,
          subCategory: energySubCategory.value,
          supplierId,
        }
      )
    } else if (isInsurance.value) {
      return filteredInsuranceRateList(
        insuranceRateList.value,
        {
          clientType: clientType.value,
          subCategory: insuranceSubCategory.value,
          supplierId,
        }
      )
    } else if (isTelco.value) {
      return filteredTelcoRateList(
        telcoRateList.value,
        {
          clientType: clientType.value,
          activationCost: activationCost.value,
          modem: modem.value,
          supplierId,
          telcoTechnology: telcoTechnology.value,
          voice: voice.value,
        }
      )
    }
    return []
  })

  const fetchRateList = async (): Promise<(GenericRateType)[]> => {
    try {
      setLoading(true)

      const response = await client.get({
        filters: [
          filter.at('document.type', `rate_${category.value}`),
        ],
        pageSize: 1000
      })

      const results: Record<string, any>[] = response.results.map(({ data, id, uid }) => ({ ...data, id, slug: uid }))
      if (results.length === 0) {
        return Promise.resolve([])
      }

      let parsedResponse: (GenericRateType)[] = []

      if (category.value === Category.ENERGY) {
        parsedResponse = parseEnergyRateList(results, category.value)
      } else if (category.value === Category.INSURANCE) {
        parsedResponse = parseInsuranceRateList(results, category.value)
      } else if (category.value === Category.TELCO) {
        parsedResponse = parseTelcoRateList(results, category.value)
      }

      return Promise.resolve(parsedResponse)
    } catch (error) {
      console.error('Error fetching rate data by category from Prismic:', error)
      return Promise.resolve([])
    } finally {
      setLoading(false)
    }
  }

  const fetchRateById = async (id: string, category?: CategoryType): Promise<GenericRateType | undefined> => {
    try {
      setLoading(true)

      const response = await client.getByID(id)

      const data = { id: response.id, slug: response, ...response.data }

      let parsedResponse: GenericRateType | undefined = undefined

      if (category === Category.ENERGY) {
        parsedResponse = parseEnergyRate(data, category)
      } else if (category === Category.INSURANCE) {
        parsedResponse = parseInsuranceRate(data, category)
      } else if (category === Category.TELCO) {
        parsedResponse = parseTelcoRate(data, category)
      }

      return Promise.resolve(parsedResponse)
    } catch (error) {
      console.error('Error fetching rate data by id from Prismic:', error)
      return Promise.resolve(undefined)
    } finally {
      setLoading(false)
    }
  }

  const fetchRateBySlug = async (slug: string, category?: CategoryType): Promise<GenericRateType | undefined> => {
    try {
      setLoading(true)

      const response = await client.getByUID(`rate_${category}`, slug)

      const data = { id: response.id, slug: response.uid, ...response.data }

      let parsedResponse: GenericRateType | undefined = undefined

      if (category === Category.ENERGY) {
        parsedResponse = parseEnergyRate(data, category)
      } else if (category === Category.INSURANCE) {
        parsedResponse = parseInsuranceRate(data, category)
      } else if (category === Category.TELCO) {
        parsedResponse = parseTelcoRate(data, category)
      }

      return Promise.resolve(parsedResponse)
    } catch (error) {
      console.error('Error fetching rate data by slug from Prismic:', error)
      return Promise.resolve(undefined)
    } finally {
      setLoading(false)
    }
  }

  const loadRateList = async (): Promise<boolean> => {
    rateList.value = await fetchRateList() || []
    return true
  }

  const loadRateByIdOrSlug = async (value: string, _category = category.value): Promise<boolean> => {
    const isPrismicID = prismicIDPattern.test(value)
    const isPrismicSllug = slugPattern.test(value)

    const rate = isPrismicID ? await fetchRateById(value, _category) : await fetchRateBySlug(value, _category)
    if (rate === undefined) {
      return false
    }

    rateList.value.push(rate)
    return true
  }

  const getRateByIdOrSlug = (value: string) => {
    return rateList.value.find(({ id, slug }) => id === value || slug === value)
  }

  const setRateById = (id: string, value: EnergyRateType) => {
    const idx = rateList.value.findIndex((data: any) => data.id === id)
    if (idx === -1) {
      return
    }

    rateList.value[idx] = value
  }

  /**
   * Used to set the rate to be shown in the dialog,
   * will automatically open the rate dialog
   * @param rate context rate
   */
  const setSelectedRate = (rate: GenericRateType) => {
    selectedRate.value = rate
  }

  /**
   * Used to reset the rate to be shown in the dialog,
   * will automatically close the rate dialog
   */
  const clearSelectedRate = () => {
    selectedRate.value = undefined
  }

  if (process.client) {
    const setRatesPriceDetail = throttle(() => {
      if (isInsurance.value || isTelco.value) {
        return
      }

      filteredRateList.value.map((rate: GenericRateType) => rateWithPriceInfo(rate as EnergyRateType))
    }, 1200)

    watch(() => [
      filteredRateList.value,
      estimatedConsumeInKwh.value,
      estimatedConsumeInCubicMeters.value,
      consumeInCubicMeters.value,
      consumeInKwh.value,
      consumeKnown.value,
    ],
      (newValues, oldValues) => {
        // Check if any of the values have changed
        const hasChange = !isEqual(newValues, oldValues)
        if (!hasChange) {
          return
        }

        setRatesPriceDetail()
      }, { immediate: true }
    )
  }

  return {
    rateList,
    selectedRate,
    filteredRateList,

    fetchRateList,
    fetchRateById,
    loadRateList,
    loadRateByIdOrSlug,
    getRateByIdOrSlug,
    setRateById,
    setSelectedRate,
    clearSelectedRate,
  }
})
